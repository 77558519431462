
import { Component, Vue, Prop } from "vue-property-decorator";
import vMultiselectListbox from "vue-multiselect-listbox";
import ToastService from "../../services/helper/ToastService";
import draggable from "vuedraggable";
import Multiselect from "vue-multiselect";
import Discipline from "@/models/sporteventCalculation/Discipline";
import SporteventCompetition from "@/models/sportevent/SporteventCompetition";
import Competition from "@/models/sporteventCalculation/Competition";

@Component({
  components: {
    "v-multiselect-listbox": vMultiselectListbox,
  },
})
export default class SporteventCompetitionsDisciplineComponent extends Vue {
  public loading = true;

  @Prop({ required: true })
  public discipline!: Discipline;

  @Prop({ required: true })
  public edit!: boolean;

  @Prop({ required: true })
  public sporteventCompetitions!: SporteventCompetition[];

  public sporteventCompetitionsThisDiscipline!: SporteventCompetition[];

  async mounted(): Promise<void> {
    this.sporteventCompetitionsThisDiscipline = this.sporteventCompetitions.filter((sc: SporteventCompetition) => sc.disciplines.findIndex((d=> d.id == this.discipline.id)) > 0);
    this.loading = false;
  }

  getCompetitionName(sc: SporteventCompetition): string {
    sc = new SporteventCompetition(sc);
    return `${sc.competition.name}`;
  }
  getCompetitionId(sc: SporteventCompetition): string {
    sc = new SporteventCompetition(sc);
    return `${sc.id}`;
  }

  editMode(mode = false){
    this.edit = mode;
  }

}
