
import { Component, Vue, Watch } from "vue-property-decorator";
import ToastService from "../../services/helper/ToastService";
import i18n from "../../plugins/i18n";
import draggable from "vuedraggable";
import Multiselect from "vue-multiselect";

import SporteventService from "../../services/sportevent/SporteventService";
import SporteventCompetitionService from "../../services/sportevent/SporteventCompetitionService";
import SporteventCompetition from "../../models/sportevent/SporteventCompetition";
import Sportevent from "../../models/sportevent/Sportevent";

import DisciplineService from "../../services/sporteventCalculation/DisciplineService";
import Discipline from "../../models/sporteventCalculation/Discipline";
import Competition from "@/models/sporteventCalculation/Competition";
import SporteventCompetitionsDisciplineComponent from "./SporteventCompetitionsDiscipline.vue";

@Component({
  components: {
    draggable,
    Multiselect,
    SporteventCompetitionsDisciplineComponent,
  },
})
export default class SporteventCompetitionsComponentNext extends Vue {
  public loading = true;
  public allDisciplines: Discipline[] = [];
  public addDiscipline: Discipline =  new Discipline();
  public currentDisciplines: Discipline[] = [];
  public sportevent: Sportevent = new Sportevent();
  public filter = "";

  async mounted(): Promise<void> {
    this.allDisciplines = (await DisciplineService.getAll(true)).sort((a, b) => (a.name > b.name ? 1 : -1))
    this.sportevent = SporteventService.get();
    this.sportevent.sporteventCompetitions.forEach((sc: SporteventCompetition) =>{
      sc.disciplines.forEach((d: Discipline) => {
        this.addDisciplineToList(d);
      })
    })
    this.sportevent.sporteventCompetitions = this.sportevent.sporteventCompetitions.sort( (a,b) => (a.competition.name > b.competition.name) ? -1 : 1)
    this.loading = false;
  }

  disciplineName(discipline): string {
    return (new Discipline(discipline)).name
  }

  addDisciplineToList(discipline: Discipline): void {
    console.log(`add ${discipline.name}`)
    if(discipline.id !== null && discipline.id !== undefined){
      if(this.currentDisciplines.findIndex(d => d.id === discipline.id) === -1){
        this.currentDisciplines.push(discipline);
        this.allDisciplines = this.allDisciplines.filter( (d) => d.id !== discipline.id)
        this.addDiscipline = new Discipline();
      }
    }
  }

  deleteDisciplineToList(discipline: Discipline): void {
    console.log(`delete ${discipline.name}`)
    if(discipline.id !== null && discipline.id !== undefined){
      if(this.allDisciplines.findIndex(d => d.id === discipline.id) === -1){
        this.allDisciplines.push(discipline);
        this.currentDisciplines = this.currentDisciplines.filter( (d) => d.id !== discipline.id)
      }
    }
  }
}
